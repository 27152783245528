import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import moment from 'moment-timezone';

import routesMap from '../../Routes';
import withOrderContext from '../../withOrderContext';
import momentFr from '../../config/moment.fr';
import {
  colors, mobileThresholdPixels, margins,
  StyledTitle2 as Title2,
} from '../../components/Home/v3/styledComponents';
import LogoButton from './LogoButton';
import Calendar from './Calendar';
import SlotSelector from './SlotSelector';
import SlotsSummary from './SlotsSummary';
import calendar from '../../assets/calendar.svg';
import calendarChecked from '../../assets/calendarChecked.svg';
import clock from '../../assets/clock.svg';
import { dateFormat, getActiveSlots } from '../../services/slotsFormatting';

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');

const isEqualDays = (selectedDay, day) => selectedDay && selectedDay.isSame(day, 'day');

function getBeginHour(selectedDay) {
  const now = moment();
  const tomorrow = moment().add(1, 'day');
  if (isEqualDays(selectedDay, now)) return Math.max(13, now.hours() + 4);
  if (isEqualDays(selectedDay, tomorrow) && now.hours() > 18) return 13;
  return 7;
}

function createSlot(hours, is30 = false) {
  return {
    hours,
    minutes: is30 ? 30 : 0,
    string1: is30 ? `${hours}h30` : `${hours}h`,
    hoursEnd: is30 ? hours + 1 : hours,
    minutesEnd: is30 ? 0 : 30,
    string2: is30 ? `${hours + 1}h` : `${hours}h30`,
    isActive: false,
  };
}

function createSlots(beginHour, endHour, removeLastOne = true) {
  const slots = [];
  let slotHour;
  for (slotHour = beginHour; slotHour <= endHour; slotHour += 1) {
    slots.push(createSlot(slotHour));
    slots.push(createSlot(slotHour, true));
  }
  if (removeLastOne) slots.pop();
  return slots;
}

const P = styled.p`
  font-family: Roboto;
  font-weight: normal;
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  color: ${colors.navy};
  margin: 40px 80px 69px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 20px 0px 40px;
    line-height: 17px;
    font-size: 14px;
    padding: 20px 30px 25px;
    margin: 0px;
  }
`;

const ComponentTitle = styled.p`
  font-family: Gotham;
  line-height: 28px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: ${props => props.noMarginTopAndBottom ? '0px 0px 0px 8px' : '30px 0px 30px 8px'};
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0 0 5px 8px;
  }
`;

const ComponentsContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent || 'space-between'};
  width: 100%;
  margin-bottom: ${props => props.largeMarginBottom ? '60' : '23'}px;
  ${props => props.noMarginBottom && 'margin-bottom: 0px;'}
  ${props => props.marginTop && 'margin-top: 100px;'}
  ${props => props.column && 'flex-direction: column; align-items: flex-start;'}
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: ${props => props.mobileReverse ? 'column-reverse' : 'column'};
    align-items: center;
    ${props => props.marginTop && 'margin-top: 60px;'}
    ${props => !props.noMarginBottom && 'margin-bottom: 20px;'}
  }
`;

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => props.borderBottom && `border-bottom: 0.75px solid ${colors.lightGrey2};`}
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 40px;
  }
`;

const ComponentHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

const TopLinkContainer = styled.div`
  position: relative;
  top: -${margins.l};
  @media (max-width: ${mobileThresholdPixels}) {
    top: -${margins.m};
    margin-bottom: ${margins.s};
    margin-top: ${margins.s};
    text-align: center;
  }
`;

class Availabilities extends React.Component {
  constructor(props) {
    super(props);
    const initialState = props.orderContext.slots[moment().format(dateFormat)]
      || {
        allDay: { isActive: false, text: 'Toute la journée' },
        slotBlocks: [
          { isActive: false, text: 'Toute la matinée', slots: createSlots(7, 12, false) },
          { isActive: false, text: "Tout l'après-midi", slots: createSlots(13, 17, false) },
          { isActive: false, text: 'Toute la soirée', slots: createSlots(18, 21) },
        ],
      };
    this.state = {
      ...initialState,
      selectedDates: props.orderContext.slots || {},
      date: getBeginHour(moment()) > 21 ? moment().add(1, 'day') : moment(),
      isSlotClickedTracked: false,
    };
    this.toggleSlot = this.toggleSlot.bind(this);
    this.togglePartDay = this.togglePartDay.bind(this);
    this.toggleAllDay = this.toggleAllDay.bind(this);
    this.selectDate = this.selectDate.bind(this);
    this.selectDateAndSlot = this.selectDateAndSlot.bind(this);
    this.goNext = this.goNext.bind(this);
    this.goToOrderSummary = this.goToOrderSummary.bind(this);
  }

  goNext() {
    const { setSlots } = this.props.orderContext;
    const { selectedDates } = this.state;
    setSlots(selectedDates);
    navigate(routesMap.Step3.url);
  }

  goToOrderSummary() {
    const { setSlots } = this.props.orderContext;
    const { selectedDates } = this.state;
    setSlots(selectedDates);
    navigate(routesMap.Step1Summary.url);
  }

  selectDateAndSlot({ allDay, slotBlocks }) {
    const { selectedDates, date } = this.state;
    const newSelectedDates = { ...selectedDates };
    newSelectedDates[date.format(dateFormat)] = {
      date: moment(date),
      allDay: { ...allDay },
      slotBlocks: [...slotBlocks],
    };
    this.setState({
      allDay,
      slotBlocks,
      selectedDates: { ...newSelectedDates },
    });
  }

  toggleAllDay() {
    const { allDay, slotBlocks } = this.state;
    slotBlocks.forEach((partDaySlots, index) => {
      if (partDaySlots.isActive === allDay.isActive) {
        this.togglePartDay(index);
      }
    });

    this.setState({
      allDay: {
        ...allDay,
        isActive: !allDay.isActive,
      },
    });
  }

  togglePartDay(indexPartDay) {
    const { allDay, slotBlocks } = this.state;
    const newSlotBlocks = [...slotBlocks];
    newSlotBlocks[indexPartDay].isActive = !newSlotBlocks[indexPartDay].isActive;
    newSlotBlocks[indexPartDay].slots = newSlotBlocks[indexPartDay].slots
      .map(slot => ({ ...slot, isActive: newSlotBlocks[indexPartDay].isActive }));
    const slotBlocksAreAllActives = newSlotBlocks.reduce((acc, slotBlock) => acc ? slotBlock.isActive : false, true);
    if (slotBlocksAreAllActives) {
      this.toggleAllDay();
    } else {
      this.selectDateAndSlot({
        allDay: { ...allDay, isActive: false },
        slotBlocks: [...newSlotBlocks],
      });
    }
  }

  toggleSlot(indexPartDay, indexSlot) {
    const { allDay, slotBlocks } = this.state;
    const newSlotBlocks = [...slotBlocks];
    newSlotBlocks[indexPartDay].slots[indexSlot].isActive =
      !newSlotBlocks[indexPartDay].slots[indexSlot].isActive;
    const slotsAreAllActives = newSlotBlocks[indexPartDay].slots
      .reduce((acc, slot) => acc ? slot.isActive : false, true);
    if (slotsAreAllActives) {
      this.togglePartDay(indexPartDay);
    } else {
      if (!newSlotBlocks[indexPartDay].slots[indexSlot].isActive && newSlotBlocks[indexPartDay].isActive) {
        newSlotBlocks[indexPartDay].isActive = false;
      }
      this.selectDateAndSlot({
        allDay: { ...allDay, isActive: false },
        slotBlocks: [...newSlotBlocks],
      });
    }
  }

  selectDate(selectedDate) {
    const { date, selectedDates } = this.state;
    if (date !== selectedDate) {
      const newSlotBlocks = selectedDates[selectedDate.format(dateFormat)]
        ? [...selectedDates[selectedDate.format(dateFormat)].slotBlocks]
        : [
          { isActive: false, text: 'Toute la matinée', slots: createSlots(7, 12, false) },
          { isActive: false, text: "Tout l'après-midi", slots: createSlots(13, 17, false) },
          { isActive: false, text: 'Toute la soirée', slots: createSlots(18, 21) },
        ];
      const newAllDay = selectedDates[selectedDate.format(dateFormat)]
        ? { ...selectedDates[selectedDate.format(dateFormat)].allDay }
        : { isActive: false, text: 'Toute la journée' };
      this.setState({
        allDay: { ...newAllDay },
        slotBlocks: [...newSlotBlocks],
        date: moment(selectedDate),
      });
    }
  }

  render() {
    const { allDay, slotBlocks, selectedDates, date } = this.state;
    const { orderContext: { isFastVariant, setEditingClothIndex } } = this.props;
    const beginHour = getBeginHour(date);
    const activeSlots = getActiveSlots(selectedDates);
    return (
      <div>
        {isFastVariant &&
          <TopLinkContainer>
            <Link
              to={routesMap.Step1.url}
              onClick={() => setEditingClothIndex(0)}
              style={{ color: colors.navy }}
            >
              {'Cliquer ici pour faire l\'estimation de mon client'}
            </Link>
          </TopLinkContainer>
        }
        <Title2>{'Quand votre client est disponible ?'}</Title2>
        <P>
          Pendant le RDV à domicile, le couturier valide avec votre client ses attentes,
          prend ses mesures et marque les retouches.
          Il repart ensuite avec le vêtement dans son atelier.
        </P>
        <ComponentsContainer>
          <ComponentContainer borderBottom>
            <ComponentHeader>
              <img src={calendar} alt="Sélectionnez plusieurs dates" />
              <ComponentTitle>Sélectionnez plusieurs dates</ComponentTitle>
            </ComponentHeader>
            <Calendar selectDate={this.selectDate} selectedDate={date} selectedDates={selectedDates} />
          </ComponentContainer>
          <ComponentContainer>
            <ComponentHeader>
              <img src={clock} alt="Sélectionnez plusieurs créneaux" />
              <ComponentTitle>Et plusieurs créneaux</ComponentTitle>
            </ComponentHeader>
            <SlotSelector
              allDay={allDay} slotBlocks={slotBlocks}
              beginHour={beginHour}
              toggleAllDay={this.toggleAllDay}
              togglePartDay={this.togglePartDay}
              toggleSlot={this.toggleSlot}
            />
          </ComponentContainer>
        </ComponentsContainer>
        <ComponentsContainer justifyContent="flex-start" column largeMarginBottom>
          <ComponentHeader>
            <img src={calendarChecked} alt="Créneaux sélectionnés" />
            <ComponentTitle noMarginTopAndBottom>Créneaux sélectionnés</ComponentTitle>
          </ComponentHeader>
          <ComponentContainer>
            {selectedDates && Object.keys(selectedDates).length > 0 && <SlotsSummary selectedDates={selectedDates} />}
          </ComponentContainer>
        </ComponentsContainer>
        <ComponentsContainer mobileReverse>
          <StyledLink onClick={this.goToOrderSummary}>
            <LogoButton back noZoom>Modifier la commande</LogoButton>
          </StyledLink>
          {activeSlots.length > 0 &&
            <StyledLink onClick={this.goNext}>
              <LogoButton>{"Dernière étape, l'adresse"}</LogoButton>
            </StyledLink>
          }
          {activeSlots.length === 0 &&
            <LogoButton isAvailable={false}>{"Dernière étape, l'adresse"}</LogoButton>
          }
        </ComponentsContainer>
      </div>
    );
  }
}

Availabilities.propTypes = {
  orderContext: PropTypes.shape({
    setSlots: PropTypes.func.isRequired,
    slots: PropTypes.shape({}),
    isFastVariant: PropTypes.bool,
    setEditingClothIndex: PropTypes.func,
  }).isRequired,
};

export default withOrderContext(Availabilities);
